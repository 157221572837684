import UtilsURL from 'CommonUtils/url';

const useUrl = () => {
  const updateUrlParams = (value, key) => {
    const url = new URL(window.location.href);
    url.searchParams.set(key, value);
    window.history.pushState({ path: url.href }, '', url.href);
  };

  const getCurrentParam = key => {
    const newUrl = new URL(window.location.href);
    return newUrl.searchParams.get(key);
  };

  const hasParam = (key,value) => {
    const hasCurrentParam = getCurrentParam(key);
    if(hasCurrentParam && hasCurrentParam === value) return true;
    return false;
  }

  const removeParam = (key) => {
      const url = window.location.href;
      const r = new URL(url)
      r.searchParams.delete(key)
      const newUrl = r.href
      window.history.pushState({ path: newUrl }, '', newUrl)
  }

  const insertParam = (key, value) => {
    if (window && window.history.pushState) {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set(key, value);
        const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
        window.history.pushState({path: newurl}, '', newurl);
    }
  }

  const insertParamtoURL = (url, key, value) => {  
    const currentUrl = new URL(url);  
    const searchParams = new URLSearchParams(currentUrl);
    searchParams.set(key, value);
    const newUrl =
      currentUrl.protocol +
      '//' +
      currentUrl.host +
      currentUrl.pathname +
      '?' +
      searchParams.toString();        
    return newUrl;
  }

  const updateNewUrl = (newUrl) => {
    if (!window?.history?.pushState || !UtilsURL.isValidUrl(newUrl) ) return;
    
    const url = UtilsURL.sanitize(newUrl)
    window.history.pushState({}, '', url);
    
  };

  return {
    updateUrlParams,
    getCurrentParam,
    removeParam,
    insertParam,
    insertParamtoURL,
    hasParam,
    updateNewUrl,
  };
};

export default useUrl;
