import { computed } from 'vue';
import useUrl from 'CommonComposables/useUrl.js';

const WINDOW_TYPES = {
  SELF:   '1',
  BLANK:  '2',
  MODAL:  '3',
}

export default function useBadgeLink(props, emit) {
  const { insertParamtoURL } = useUrl();
  
  const isOpenInModal = computed(() => {
    return props.windowType === WINDOW_TYPES.MODAL;
  });

  const target = computed(() => {
    return props.windowType === WINDOW_TYPES.SELF ? '_self' : '_blank';
  });

  function openInModal() {
    const promotionUrlModal = insertParamtoURL(props.promoBadgeUrl, 'modal', 1);
    emit('promoSelected', promotionUrlModal);
  }

  return {
    isOpenInModal,
    openInModal,
    target,
  };
}
